.contact-title {
  font-size: 30px;
  color: white;
}

.contact-body {
  font-size: 22px;
}

.contact-email {
  color: white;
  text-decoration: none;
}

.contact-email:hover {
  color: white;
  text-decoration: underline;
}
