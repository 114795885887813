.home-title {
  font-size: 30px;
  color: white;
}

.home-title-info {
  padding-top: 20px;
  padding-bottom: 20px;
}

.home-projects-info,
.home-technologies-info {
  padding-top: 20px;
}

.home-technologies-items {
  padding-bottom: 20px;
}

.margin-top {
  margin-top: 34px;
}

.home-contact-info {
  padding-bottom: 20px;
}

.home-info {
  font-size: 22px;
}

.home-project-title {
  font-size: 24px;
  font-weight: bold;
}

.home-body {
  font-size: 20px;
  padding-top: 10px;
}

.home-hut {
  color: #f9a826;
}

.home-blabber {
  color: #f50057;
}

.home-thoughts {
  color: rgb(211, 211, 211);
}

.home-tech-item {
  padding-left: 7px;
  padding-right: 7px;
  margin: 7px;
  padding-bottom: 3px;
  font-size: 20px;
  color: white;
  background-color: #3a414b;
  display: inline-block;
  border-radius: 25px;
}

.github-logo:hover {
  opacity: 0.8;
}

.zoom-in {
  transition: transform 0.2s;
}

.zoom-in:hover {
  transform: scale(1.2);
}

.the-hut-details-btn {
  background-color: #f9a826;
  color: white;
  border: none;
}

.the-hut-details-btn:hover {
  background-color: #f9a826;
}

.blabber-details-btn {
  background-color: #f50057;
  color: white;
  border: none;
}

.blabber-details-btn:hover {
  color: black;
  background-color: #f50057;
}

.the-thoughts-details-btn {
  background-color: rgb(211, 211, 211);
  color: white;
  border: none;
}

.the-thoughts-details-btn:hover {
  background-color: rgb(211, 211, 211);
  color: black;
}

.project-image {
  margin-top: 4px;
}

.nav-link-project {
  margin-top: 4px;
}

.big-button {
  font-size: 23px;
}
