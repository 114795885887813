.projects-main-title {
  font-size: 30px;
  color: white;
}

.projects-title {
  font-size: 26px;
  padding-left: 12px;
  color: white;
}

.projects-body {
  font-size: 21px;
}

.the-hut-title {
  color: #f9a826;
  font-weight: bold;
}

.blabber-title {
  color: #f50057;
  font-weight: bold;
}

.thoughts-title {
  color: rgb(211, 211, 211);
  font-weight: bold;
}

.projects-body {
  padding-left: 12px;
}

.projects-padding {
  padding-top: 15px;
}

.responsive-img {
  width: 600px;
}

.zoom-in {
  transition: transform 0.2s;
}

.zoom-in:hover {
  transform: scale(1.1);
}

.the-hut-colour {
  color: #f9a826;
  font-weight: bold;
}

.blabber-colour {
  color: #f50057;

  font-weight: bold;
}

.react-colour {
  color: rgb(97, 218, 251);
  font-weight: bold;
}

.rails-colour {
  color: rgb(197, 47, 36);
  font-weight: bold;
}

.aws-colour {
  color: rgb(255, 153, 0);
  font-weight: bold;
}

.heroku-colour {
  color: rgb(100, 74, 134);
  font-weight: bold;
}

.giphy-colour {
  color: rgb(0, 255, 153);
  font-weight: bold;
}

.bootstrap-colour {
  color: rgb(126, 18, 249);
  font-weight: bold;
}

.redux-colour {
  color: #764abc;
  font-weight: bold;
}

.material-ui-colour {
  color: #007fff;
  font-weight: bold;
}

.express-colour {
  color: #fcdc00;
  font-weight: bold;
}

.app-features {
  font-size: 19px;
}

.app-features-title {
  font-size: 21px;
  text-decoration: underline;
  color: white;
}

.app-link {
  color: white;
  text-decoration: none;
}

.app-link:hover {
  color: white;
  text-decoration: underline;
}

.padding-bottom {
  padding-bottom: 50px;
}

@media only screen and (max-width: 1200px) {
  .responsive-img {
    width: 400px;
  }
}

@media only screen and (max-width: 991px) {
  .responsive-img {
    width: 650px;
  }
}

@media only screen and (max-width: 760px) {
  .responsive-img {
    width: 400px;
  }
}
