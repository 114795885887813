.nav-items {
  width: 70%;
}

.home-name {
  font-size: 32px;
  color: white;
}

.home-dev {
  font-size: 22px;
}

.homepage-container {
  padding: 0;
  margin: 0;
  padding-top: 15px;
}

.nav-item-navlink {
  color: white;
  font-size: 20px;
  text-decoration: none;
}

.nav-item-navlink:hover {
  color: white;
  text-decoration: underline;
}
